<template>
  <v-data-table
    dense
    :headers="headers"
    :items="items"
    item-key="name"
    class="elevation-0"
  >
    <template v-slot:item.contentType="{ item }">
      <span v-if="item.version">
        {{ item.version.modelName | cleanedContentType }}
      </span>
      <span v-else class="grey--text">(deleted object)</span>
    </template>
    <template v-slot:item.created="{ item }">
      {{ item.created | niceDate }}
    </template>

    <template v-slot:item.user="{ item }">
      <span v-if="item.user">
        {{ item.user.username
        }}<span v-if="item.user.serviceAccount">&nbsp;(access token)</span>
      </span>
      <span v-else class="grey--text">(anonymous user)</span>
    </template>

    <template v-slot:item.resource="{ item }">
      <v-chip small label v-if="item.version">
        {{ item.version.package.name }}
        <span v-if="item.version.modelName === 'Registry'">:</span>
        <span v-else>==</span>
        {{ item.version.number }}
      </v-chip>
      <span v-else class="grey--text">(deleted object)</span>
    </template>
  </v-data-table>
</template>

<script>
import { niceDate } from "@/filters";
export default {
  props: ["items"],
  filters: {
    niceDate,
    cleanedContentType(value) {
      switch (value) {
        case "Registry":
          return "Docker Image";
        case "NPMPackage":
          return "NPM Package";
        default:
          return "Python package";
      }
    },
  },
  data: () => ({
    headers: [
      {
        text: "Time",
        value: "created",
      },
      {
        text: "Resource type",
        value: "contentType",
      },
      {
        text: "Action",
        value: "action",
      },
      {
        text: "User",
        value: "user",
      },
      {
        text: "Resource",
        value: "resource",
      },
    ],
  }),
  methods: {
    navigateTo(item) {
      let route;
      switch (item.resource.modelName) {
        case "Registry":
          route = "/docker/";
          break;
        case "NPMPackage":
          route = "/npm/";
          break;
        default:
          return "/python/";
      }
      return this.$router.push(`${route}${item.package.id}`);
    },
  },
};
</script>
