<template>
  <v-row class="ma-4">
    <page-header />
    <v-col cols="12">
      <v-card tile>
        <table-header
          title="Access logs"
          @refresh="$apollo.queries.accessLogs.refetch()"
        />
        <access-logs :items="accessLogs" />
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import PageHeader from "@/components/widgets/PageHeader";
import TableHeader from "@/components/widgets/TableHeader";
import AccessLogs from "@/components/tables/AccessLogs";

import { queries } from "@/gql";

const { accessLogs: query } = queries;

export default {
  components: { AccessLogs, TableHeader, PageHeader },
  apollo: {
    accessLogs: {
      query,
    },
  },
};
</script>
