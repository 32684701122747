var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers,"items":_vm.items,"item-key":"name"},scopedSlots:_vm._u([{key:"item.contentType",fn:function(ref){
var item = ref.item;
return [(item.version)?_c('span',[_vm._v(" "+_vm._s(_vm._f("cleanedContentType")(item.version.modelName))+" ")]):_c('span',{staticClass:"grey--text"},[_vm._v("(deleted object)")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("niceDate")(item.created))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('span',[_vm._v(" "+_vm._s(item.user.username)),(item.user.serviceAccount)?_c('span',[_vm._v(" (access token)")]):_vm._e()]):_c('span',{staticClass:"grey--text"},[_vm._v("(anonymous user)")])]}},{key:"item.resource",fn:function(ref){
var item = ref.item;
return [(item.version)?_c('v-chip',{attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.version.package.name)+" "),(item.version.modelName === 'Registry')?_c('span',[_vm._v(":")]):_c('span',[_vm._v("==")]),_vm._v(" "+_vm._s(item.version.number)+" ")]):_c('span',{staticClass:"grey--text"},[_vm._v("(deleted object)")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }